import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';
import {
  allowPaymentSchema,
  canUpdateReservationsSchema,
  visibilitySchema
} from './common/schemas';

const serviceSchema = z
  .object({
    name: z
      .string({ required_error: 'El nombre de división es requerido.' })
      .min(2, {
        message: 'El nombre debe tener al menos 2 caracteres.'
      })
      .max(100, 'El nombre no puede contener más de 100 caracteres.'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.long,
        `La descripción no puede contener más de ${DESCRIPTION_MAX_LENGTH.long} caracteres.`
      )
      .optional(),
    price: z.coerce
      .number({
        required_error: 'Un precio es requerido',
        invalid_type_error: 'Un precio es requerido'
      })
      .min(0, { message: 'El precio debe ser mayor a 0' }),
    reservationPct: z.coerce
      .number({
        required_error: 'El porcentaje de la seña es requerido.',
        invalid_type_error: 'El porcentaje de la seña es requerido.'
      })
      .min(0, { message: 'El porcentaje de la seña debe ser mayor a 0%' })
      .max(100, { message: 'El porcentaje de la seña debe ser menor a 100%' }),
    minPreReservationTime: z.coerce
      .number({
        required_error: 'El tiempo mínimo de pre-reserva es requerido.',
        invalid_type_error: 'El tiempo mínimo de pre-reserva es requerido.'
      })
      .min(0, {
        message: 'El tiempo mínimo de pre-reserva debe ser mayor a 0'
      }),
    sport: z.string().uuid(),
    allowPayment: allowPaymentSchema,
    visibility: visibilitySchema,
    canUpdateReservations: canUpdateReservationsSchema,
    minConsumers: z.coerce
      .number({ required_error: 'El mínimo de consumidores es requerido.' })
      .min(1, { message: 'El mínimo de consumidores debe ser mayor a 0' }),
    maxConsumers: z.coerce.number({
      required_error: 'El máximo de consumidores es requerido.'
    }),
    minAge: z.coerce.number({
      required_error: 'La edad mínima es requerida.'
    }),
    maxAge: z.coerce
      .number({
        required_error: 'La edad máxima es requerida.'
      })
      .transform((val) => (val === 0 ? undefined : val))
  })
  .superRefine((obj, ctx) => {
    if (obj.minConsumers > obj.maxConsumers) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'El mínimo de consumidores no puede ser mayor al máximo',
        path: ['minConsumers']
      });
    }

    if (obj.maxAge) {
      if (obj.minAge > obj.maxAge) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'La edad mínima no puede ser mayor a laHoy t edad máxima'
        });
      }
    }
  });

export default serviceSchema;
